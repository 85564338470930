import styles from './ImageAnimation.module.css'

export default function ImageAnimation({ scene, style = {} }: any) {
    return (
        <svg
            style={{ width: '100%', ...style }}
            viewBox="0 0 547 342"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <clipPath id="clip0_2971_11495">
                    <rect width="547" height="342" fill="white" />
                </clipPath>
            </defs>
            <g clipPath="url(#clip0_2971_11495)">
                <g id="line">
                    <path
                        d="M0 340.875C0 341.402 0.422813 341.825 0.949334 341.825H546.051C546.577 341.825 547 341.402 547 340.875C547 340.349 546.577 339.926 546.051 339.926H0.949334C0.422813 339.926 0 340.349 0 340.875Z"
                        fill="#3F3D56"
                    />
                </g>
                <g
                    id="leaves-1"
                    className={
                        scene == 1
                            ? styles.leaves1Visible
                            : styles.leaves1Invisible
                    }
                >
                    <path
                        d="M531.731 161.144C531.061 160.011 528.142 161.224 527.607 160.194C527.073 159.165 529.777 157.6 531.325 154.025C531.604 153.379 533.359 149.316 532.059 148.063C529.593 145.685 518.305 155.493 514.356 152.285C513.487 151.583 512.88 150.178 510.264 147.888C509.219 146.978 508.588 146.571 507.982 146.722C507.113 146.938 507.049 148.103 506.115 150.378C504.719 153.802 503.898 153.594 503.084 156.324C502.478 158.351 502.621 159.532 501.911 159.755C500.906 160.083 499.981 157.928 498.712 158.119C497.412 158.319 496.63 160.817 496.383 162.692C495.92 166.212 497.204 168.526 497.922 170.969C498.696 173.618 499.159 177.649 497.364 183.363L479.59 233.459C483.515 223.18 494.875 194.999 499.646 184.528C501.026 181.503 502.541 178.439 505.669 177.226C508.676 176.06 512.809 176.755 518.074 175.486C518.688 175.334 520.395 174.903 520.611 173.898C520.786 173.068 519.781 172.605 519.941 171.823C520.148 170.777 522.118 170.769 524.552 169.564C526.259 168.718 527.328 167.704 528.245 166.834C528.524 166.571 532.633 162.636 531.731 161.12V161.144Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M461.815 187.512C461.065 187.512 460.818 189.299 460.156 189.26C459.501 189.228 459.509 187.448 458.201 185.652C457.962 185.333 456.478 183.29 455.489 183.569C453.614 184.096 455.17 192.468 452.449 193.481C451.851 193.705 450.989 193.593 449.115 194.224C448.365 194.471 447.982 194.663 447.886 195.006C447.742 195.493 448.293 195.86 449.146 196.977C450.423 198.645 450.08 198.996 451.189 200.178C452.01 201.055 452.625 201.327 452.537 201.742C452.409 202.324 451.085 202.165 450.814 202.843C450.535 203.537 451.54 204.647 452.385 205.309C453.973 206.554 455.489 206.586 456.893 206.937C458.416 207.32 460.531 208.254 462.812 210.784L482.262 233.961C478.352 229.069 467.806 215.357 464.049 209.994C462.964 208.446 461.895 206.81 462.206 204.934C462.501 203.122 464.033 201.295 464.934 198.342C465.038 197.999 465.317 197.033 464.886 196.642C464.527 196.315 464.017 196.674 463.674 196.371C463.219 195.972 463.785 194.998 463.897 193.458C463.977 192.372 463.785 191.558 463.626 190.856C463.578 190.648 462.828 187.488 461.831 187.496L461.815 187.512Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M476.812 190.881L476.357 186.835L476.565 186.532C477.522 185.111 478.009 183.73 478.017 182.413C478.017 182.206 478.009 181.998 478.001 181.783C477.961 180.937 477.913 179.891 478.464 178.67C478.767 177.992 479.628 176.412 480.929 176.603C481.28 176.651 481.543 176.811 481.742 177.002C481.774 176.954 481.798 176.906 481.83 176.859C482.237 176.244 482.556 175.981 482.859 175.725C483.099 175.534 483.338 175.326 483.721 174.855C483.888 174.648 484.016 174.464 484.128 174.305C484.463 173.842 484.901 173.283 485.667 173.291C486.489 173.331 486.92 174.009 487.207 174.464C487.718 175.27 487.949 175.853 488.1 176.236C488.156 176.38 488.22 176.531 488.252 176.587C488.515 176.978 490.677 176.611 491.491 176.483C493.318 176.18 494.897 175.917 495.552 177.066C496.014 177.888 495.671 178.982 494.49 180.394C494.124 180.833 493.733 181.168 493.382 181.432C493.669 181.607 493.924 181.871 494.028 182.278C494.275 183.259 493.429 184.241 491.523 185.199C491.052 185.438 490.414 185.757 489.52 185.917C489.098 185.989 488.723 186.005 488.396 186.013C488.396 186.196 488.348 186.404 488.228 186.611C487.893 187.226 487.199 187.521 486.146 187.449C484.989 187.385 484.04 187.17 483.202 186.978C482.468 186.811 481.838 186.667 481.343 186.699C480.426 186.771 479.716 187.513 478.886 188.463L476.812 190.889V190.881Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M478.91 166.794L475.113 168.255L474.762 168.135C473.15 167.568 471.682 167.449 470.414 167.776C470.215 167.832 470.015 167.887 469.808 167.951C469.002 168.207 467.997 168.518 466.68 168.295C465.947 168.175 464.199 167.736 464.064 166.435C464.024 166.084 464.112 165.788 464.239 165.549C464.183 165.533 464.136 165.517 464.08 165.493C463.386 165.262 463.051 165.014 462.724 164.783C462.476 164.607 462.221 164.416 461.663 164.168C461.415 164.057 461.208 163.977 461.032 163.913C460.498 163.705 459.844 163.426 459.66 162.676C459.493 161.87 460.035 161.279 460.402 160.888C461.048 160.194 461.551 159.819 461.886 159.571C462.006 159.484 462.141 159.38 462.181 159.34C462.492 158.981 461.591 156.985 461.256 156.235C460.498 154.543 459.844 153.083 460.793 152.157C461.471 151.495 462.612 151.558 464.279 152.341C464.798 152.588 465.221 152.883 465.564 153.147C465.659 152.827 465.843 152.508 466.218 152.301C467.103 151.814 468.268 152.38 469.68 153.985C470.031 154.384 470.502 154.918 470.877 155.74C471.052 156.132 471.164 156.491 471.26 156.802C471.443 156.762 471.651 156.754 471.882 156.802C472.56 156.97 473.023 157.568 473.222 158.606C473.453 159.739 473.485 160.713 473.509 161.575C473.533 162.325 473.557 162.971 473.717 163.442C474.02 164.312 474.913 164.815 476.046 165.373L478.918 166.77L478.91 166.794Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M476.812 154.877L476.357 150.831L476.565 150.528C477.522 149.107 478.009 147.726 478.017 146.409C478.017 146.202 478.009 145.994 478.001 145.779C477.961 144.933 477.913 143.887 478.464 142.666C478.767 141.988 479.628 140.408 480.929 140.599C481.28 140.647 481.543 140.807 481.742 140.998C481.774 140.95 481.798 140.903 481.83 140.855C482.237 140.24 482.556 139.977 482.859 139.721C483.099 139.53 483.338 139.322 483.721 138.851C483.888 138.644 484.016 138.46 484.128 138.301C484.463 137.838 484.901 137.279 485.667 137.287C486.489 137.327 486.92 138.005 487.207 138.46C487.718 139.266 487.949 139.849 488.1 140.232C488.156 140.376 488.22 140.527 488.252 140.583C488.515 140.974 490.677 140.607 491.491 140.48C493.318 140.176 494.897 139.913 495.552 141.062C496.014 141.884 495.671 142.978 494.49 144.39C494.124 144.829 493.733 145.164 493.382 145.428C493.669 145.603 493.924 145.867 494.028 146.274C494.275 147.255 493.429 148.237 491.523 149.195C491.052 149.434 490.414 149.753 489.52 149.913C489.098 149.985 488.723 150.001 488.396 150.009C488.396 150.192 488.348 150.4 488.228 150.607C487.893 151.222 487.199 151.517 486.146 151.445C484.989 151.382 484.04 151.166 483.202 150.975C482.468 150.807 481.838 150.663 481.343 150.695C480.426 150.767 479.716 151.509 478.886 152.459L476.812 154.885V154.877Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M480.946 233.395L480.1 232.861L479.111 232.972C479.095 232.876 479.039 232.669 478.959 232.342C478.521 230.53 477.172 225.031 476.048 216.364C475.266 210.314 474.819 204.089 474.723 197.872C474.636 191.639 474.923 186.922 475.162 183.131C475.338 180.274 475.561 177.552 475.768 174.895C476.335 167.808 476.869 161.112 476.47 153.705C476.383 152.053 476.199 148.606 474.189 144.942C473.024 142.819 471.413 140.928 469.41 139.34L470.655 137.775C472.864 139.539 474.644 141.63 475.936 143.985C478.162 148.047 478.369 151.798 478.465 153.602C478.872 161.144 478.329 167.904 477.755 175.054C477.547 177.696 477.324 180.41 477.148 183.259C476.917 187.018 476.622 191.687 476.71 197.84C476.797 203.985 477.236 210.131 478.01 216.108C479.119 224.664 480.451 230.091 480.882 231.871C481.113 232.821 481.161 233.02 480.922 233.395H480.946Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M465.756 142.524C465.677 142.524 465.589 142.524 465.509 142.524C463.81 142.428 462.23 141.414 460.826 139.491C460.164 138.589 459.829 137.559 459.159 135.508C459.055 135.189 458.545 133.585 458.242 131.374C458.042 129.93 458.066 129.331 458.353 128.796C458.672 128.206 459.183 127.791 459.765 127.511C459.741 127.32 459.765 127.12 459.845 126.921C460.188 126.091 461.09 126.195 461.584 126.25C461.831 126.282 462.143 126.322 462.478 126.306C463.004 126.282 463.283 126.123 463.714 125.891C464.121 125.668 464.632 125.388 465.389 125.237C466.873 124.926 468.126 125.349 468.533 125.484C470.695 126.203 471.764 127.887 472.992 129.834C473.239 130.225 474.085 131.646 474.643 133.593C475.042 134.998 474.987 135.628 474.867 136.091C474.62 137.049 474.037 137.591 472.561 138.796C471.022 140.057 470.248 140.696 469.586 141.079C468.038 141.973 467.073 142.532 465.78 142.532L465.756 142.524Z"
                        fill="#F2F2F2"
                    />
                </g>

                <g
                    className={
                        scene == 1
                            ? styles.verticalGiftVisible
                            : styles.verticalGiftInvisible
                    }
                >
                    <path
                        d="M226.164 341.585H55.7788C48.974 341.585 43.4375 336.046 43.4375 329.238V12.3465C43.4375 5.53878 48.974 0 55.7788 0H226.164C232.969 0 238.506 5.53878 238.506 12.3465V329.238C238.506 336.046 232.969 341.585 226.164 341.585ZM55.7788 1.59619C49.8515 1.59619 45.033 6.41669 45.033 12.3465V329.238C45.033 335.168 49.8515 339.989 55.7788 339.989H226.164C232.092 339.989 236.91 335.168 236.91 329.238V12.3465C236.91 6.41669 232.092 1.59619 226.164 1.59619H55.7788Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M238.977 140.871H42.959V191.806H238.977V140.871Z"
                        fill="#2A7AFF"
                    />
                    <path
                        d="M167.696 0.798828H116.783V340.788H167.696V0.798828Z"
                        fill="#2A7AFF"
                    />
                    <path
                        opacity="0.2"
                        d="M169.58 187.967C169.939 186.562 170.155 185.11 170.21 183.602L233.888 125.588L179.153 98.8438L155.029 163.194C153.378 162.763 151.662 162.508 149.875 162.508C148.926 162.508 148.001 162.596 147.091 162.723L123.143 98.8438L68.4082 125.588L129.58 181.327C129.541 181.846 129.501 182.364 129.501 182.891C129.501 184.647 129.748 186.347 130.163 187.967C92.6681 199.691 77.3112 235.095 77.3112 235.095L124.403 273.3C121.595 245.199 138.747 216.435 148.017 203.171C148.623 203.227 149.237 203.267 149.859 203.267C150.482 203.267 151.088 203.227 151.702 203.171C160.972 216.427 178.132 245.199 175.316 273.3L222.408 235.095C222.408 235.095 207.059 199.691 169.556 187.967H169.58Z"
                        fill="black"
                    />
                    <path
                        d="M176.497 259.534C175.947 259.534 175.396 259.414 174.87 259.166C173.545 258.552 172.716 257.259 172.692 255.806C172.309 231.169 158.268 206.995 149.932 194.776C149.373 193.962 148.416 193.475 147.363 193.475C146.27 193.475 145.313 193.962 144.754 194.776C136.418 206.995 122.377 231.177 121.994 255.806C121.97 257.259 121.132 258.544 119.816 259.166C118.484 259.789 116.944 259.605 115.795 258.68L76.6494 226.923C75.2533 225.79 74.8385 223.803 75.6761 222.199C79.633 214.577 94.8702 188.878 124.762 178.319C126.095 177.849 126.908 176.516 126.733 175.087C126.637 174.289 126.589 173.483 126.589 172.701C126.589 171.767 126.23 170.913 125.608 170.346L69.3339 119.077C68.4006 118.223 67.9538 116.97 68.1293 115.725C68.3048 114.472 69.0946 113.394 70.2274 112.844L117.08 89.9542C118.037 89.4833 119.162 89.4434 120.159 89.8345C121.156 90.2335 121.938 91.0316 122.313 92.0372L144.036 149.995C144.483 151.192 145.632 151.974 146.94 151.942C147.921 151.918 148.926 151.974 149.916 152.11C151.32 152.301 152.644 151.503 153.139 150.194L174.933 92.0452C175.308 91.0396 176.098 90.2415 177.087 89.8425C178.085 89.4514 179.201 89.4913 180.167 89.9622L227.019 112.852C228.152 113.402 228.942 114.48 229.117 115.733C229.293 116.986 228.846 118.239 227.913 119.085L168.95 172.805C168.4 173.307 168.057 173.986 167.985 174.728C167.969 174.888 167.953 175.047 167.937 175.207C167.778 176.556 168.607 177.864 169.923 178.327C199.824 188.894 215.061 214.585 219.018 222.215C219.847 223.819 219.44 225.806 218.044 226.939L178.898 258.696C178.204 259.262 177.359 259.55 176.505 259.55L176.497 259.534Z"
                        fill="#2A7AFF"
                    />
                </g>

                <g
                    id="horizontal-gift"
                    className={
                        scene == 1
                            ? styles.horizontalGiftVisible
                            : styles.horizontalGiftInvisible
                    }
                >
                    <path
                        d="M507.105 341.897H342.718C335.913 341.897 330.377 336.358 330.377 329.55V245.87C330.377 239.062 335.913 233.523 342.718 233.523H507.105C513.91 233.523 519.446 239.062 519.446 245.87V329.55C519.446 336.358 513.91 341.897 507.105 341.897ZM342.718 235.128C336.791 235.128 331.972 239.948 331.972 245.878V329.558C331.972 335.488 336.791 340.309 342.718 340.309H507.105C513.032 340.309 517.851 335.488 517.851 329.558V245.878C517.851 239.948 513.032 235.128 507.105 235.128H342.718Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M441.409 233.627H413.32V341.801H441.409V233.627Z"
                        fill="#AA00FF"
                    />
                    <path
                        d="M518.64 274.369H331.166V302.47H518.64V274.369Z"
                        fill="#AA00FF"
                    />
                    <path
                        opacity="0.2"
                        d="M419.423 305.894C420.197 306.093 421.003 306.213 421.832 306.237L453.822 341.377L468.565 311.169L433.081 297.857C433.32 296.947 433.456 295.997 433.456 295.015C433.456 294.497 433.408 293.986 433.336 293.475L468.557 280.259L453.814 250.051L423.085 283.81C422.798 283.786 422.51 283.77 422.223 283.77C421.258 283.77 420.316 283.906 419.423 284.137C412.961 263.443 393.432 254.967 393.432 254.967L372.371 280.961C387.864 279.413 403.731 288.878 411.039 293.994C411.007 294.329 410.991 294.664 410.991 295.008C410.991 295.351 411.015 295.686 411.039 296.021C403.723 301.137 387.864 310.602 372.371 309.054L393.432 335.048C393.432 335.048 412.953 326.572 419.423 305.878V305.894Z"
                        fill="black"
                    />
                    <path
                        d="M454.978 336.557C454.34 336.557 453.734 336.293 453.295 335.814L423.674 303.276C423.427 303.005 423.076 302.837 422.669 302.797L422.541 302.781C421.815 302.701 421.161 303.092 420.93 303.739C415.082 320.323 400.85 328.759 396.622 330.954C395.673 331.449 394.492 331.201 393.814 330.363L376.303 308.759C375.745 308.073 375.633 307.155 376.008 306.349C376.375 305.559 377.141 305.064 378.01 305.048C391.548 304.84 404.847 297.107 411.572 292.518C411.979 292.238 412.218 291.767 412.218 291.241C412.218 290.698 411.979 290.219 411.572 289.94C404.847 285.351 391.548 277.617 378.01 277.41C377.141 277.394 376.375 276.899 376.008 276.109C375.633 275.311 375.745 274.385 376.303 273.699L393.814 252.094C394.492 251.256 395.673 251.009 396.622 251.503C400.842 253.698 415.074 262.134 420.922 278.711C421.145 279.349 421.799 279.756 422.446 279.676C423.156 279.588 423.858 279.565 424.544 279.62L453.287 248.048C453.798 247.489 454.548 247.218 455.297 247.329C456.047 247.433 456.694 247.904 457.021 248.582L469.641 274.441C469.92 275.015 469.944 275.686 469.713 276.284C469.474 276.883 468.995 277.346 468.397 277.577L436.438 289.565C435.848 289.788 435.465 290.363 435.481 290.993V291.225C435.481 291.72 435.449 292.23 435.377 292.725C435.29 293.372 435.689 294.01 436.335 294.249L468.397 306.277C468.995 306.5 469.474 306.971 469.713 307.57C469.952 308.168 469.92 308.839 469.641 309.413L457.021 335.272C456.686 335.95 456.047 336.421 455.297 336.525C455.186 336.541 455.082 336.549 454.97 336.549L454.978 336.557Z"
                        fill="#AA00FF"
                    />
                </g>

                <g
                    id="person-middle"
                    className={
                        scene == 1
                            ? styles.personMiddleVisible
                            : styles.personMiddleInvisible
                    }
                >
                    <path
                        d="M306.093 137.026H280.381V128.559C280.381 114.36 291.901 102.836 306.093 102.836V137.026Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M300.396 121.215L304.815 141.103L278.912 136.801L289.466 117.99L300.396 121.215Z"
                        fill="#FFB6B6"
                    />
                    <path
                        opacity="0.1"
                        d="M300.396 121.215L304.815 141.103L278.912 136.801L289.466 117.99L300.396 121.215Z"
                        fill="black"
                    />
                    <path
                        d="M300.397 163.705H275.562V200.473H300.397V163.705Z"
                        fill="#FFB6B6"
                    />
                    <path
                        d="M316.535 230.539L311.07 259.853C311.07 259.853 314.548 280.229 304.114 301.091L297.157 322.456L286.73 320.963C286.73 320.963 293.184 305.065 293.184 298.106C293.184 291.147 294.676 264.315 294.676 264.315L293.934 233.7L316.535 230.531V230.539Z"
                        fill="#FFB6B6"
                    />
                    <path
                        d="M285.246 320.475C285.246 320.475 297.164 316.006 298.162 322.463L297.667 325.448C297.667 325.448 302.135 338.864 295.681 339.861C289.227 340.859 283.268 342.343 282.765 339.366C282.262 336.389 282.278 332.662 283.515 330.053C284.751 327.443 285.246 320.483 285.246 320.483V320.475Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M278.937 225.99L276.033 255.671C276.033 255.671 267.026 274.275 271.247 297.22L271.989 319.679L282.423 321.147C282.423 321.147 280.644 304.076 282.575 297.396C284.505 290.716 290.536 264.522 290.536 264.522L299.767 235.32L278.937 225.99Z"
                        fill="#FFB6B6"
                    />
                    <path
                        d="M283.995 321.083C283.995 321.083 273.791 313.469 271.039 319.399L270.688 322.4C270.688 322.4 262.806 337.236 268.734 339.99C274.661 342.743 279.831 342.639 281.131 339.91C282.431 337.18 283.46 333.605 282.998 330.756C282.535 327.906 283.995 321.083 283.995 321.083Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M310.081 183.332L331.437 228.552C331.437 228.552 288.477 250.165 263.141 250.165L275.721 183.332H310.081Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M290.958 130.41L303.371 132.397L302.374 163.698L316.279 191.528L298.401 200.474C298.401 200.474 284.496 174.137 282.007 157.744C279.518 141.351 281.01 129.42 281.01 129.42L290.942 130.418L290.958 130.41Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M281.521 131.408L269.108 133.395L270.105 164.696L267.121 193.516L274.078 201.465C274.078 201.465 287.983 175.127 290.472 158.735C292.961 142.342 291.469 130.41 291.469 130.41L281.537 131.408H281.521Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M300.644 133.148L303.372 132.405C303.372 132.405 313.057 131.16 314.549 135.135C316.041 139.109 315.546 162.462 315.546 162.462C315.546 162.462 318.107 191.121 308.135 191.449C298.163 191.776 300.15 167.929 300.15 167.929L300.644 133.148Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M296.057 126.674C303.155 126.674 308.909 120.917 308.909 113.816C308.909 106.715 303.155 100.959 296.057 100.959C288.959 100.959 283.205 106.715 283.205 113.816C283.205 120.917 288.959 126.674 296.057 126.674Z"
                        fill="#FFB6B6"
                    />
                    <path
                        d="M292.036 131.512C294.796 130.809 297.924 133.754 299.016 138.096C299.479 139.82 299.519 141.632 299.144 143.379L300.333 148.312H300.421L309.77 170.347C311.94 175.471 311.717 181.353 308.941 186.181C307.289 189.054 305.127 190.986 302.582 189.015C298.362 185.742 295.61 167.027 294.35 156.277L291.438 145.351C290.273 143.994 289.443 142.382 289.028 140.642C287.928 136.308 289.276 132.222 292.028 131.528L292.036 131.512Z"
                        fill="#FFB6B6"
                    />
                    <path
                        d="M280.029 133.148L277.301 132.405C277.301 132.405 267.616 131.16 266.124 135.135C264.632 139.109 265.127 162.462 265.127 162.462C265.127 162.462 262.566 191.121 272.538 191.449C282.51 191.776 280.524 167.929 280.524 167.929L280.029 133.148Z"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M288.637 131.512C285.876 130.809 282.749 133.754 281.656 138.096C281.194 139.82 281.154 141.632 281.529 143.379L280.34 148.312H280.252L270.902 170.347C268.732 175.471 268.956 181.353 271.732 186.181C273.383 189.054 275.545 190.986 278.09 189.015C282.31 185.742 285.063 167.027 286.323 156.277L289.235 145.351C290.4 143.994 291.229 142.382 291.644 140.642C292.745 136.308 291.397 132.222 288.645 131.528L288.637 131.512Z"
                        fill="#FFB6B6"
                    />
                    <path
                        d="M271.452 124.216C272.107 122.229 273.399 120.489 273.973 118.47C274.739 115.796 276.375 121.726 276.534 118.949C276.837 113.673 284.887 100.106 289.482 97.496C294.077 94.8862 296.718 95.6763 301.392 98.1265C301.392 98.1265 303.666 98.2701 306.49 99.6668C309.314 101.063 311.867 103.298 312.968 106.259C313.837 108.605 313.742 111.175 314.037 113.665C314.851 120.585 318.728 127.169 317.898 134.089C317.635 136.244 316.821 138.478 315.05 139.747C313.287 141.016 310.439 140.88 309.274 139.045C308.875 138.414 308.708 137.672 308.373 137.018C308.03 136.355 307.423 135.741 306.682 135.765C305.565 135.797 304.998 137.289 305.437 138.319C305.876 139.348 306.913 139.963 307.878 140.513C305.573 141.551 302.701 141.232 300.682 139.699C298.664 138.175 297.571 135.501 297.946 132.995C298.473 129.468 303.61 130.194 304.216 126.674C304.655 124.112 306.203 119.827 305.788 117.265C305.365 114.703 303.339 117.808 305.477 116.323C303.132 117.456 298.106 109.707 295.649 108.845C293.191 107.975 291.109 106.099 289.992 103.745C287.384 107.528 285.094 106.499 284.161 110.992C283.227 115.485 284.089 125.701 287.934 128.215C286.395 129.268 285.166 130.769 284.448 132.485C284.041 133.458 283.778 134.52 283.092 135.334C281.799 136.874 279.398 136.97 277.492 136.323C275.122 135.517 273.104 133.753 271.987 131.511C270.87 129.268 270.679 126.595 271.46 124.216H271.452Z"
                        fill="#2F2E41"
                    />
                </g>

                <g
                    id="square-gift"
                    className={
                        scene == 2
                            ? styles.squareGiftVisible
                            : styles.squareGiftInvisible
                    }
                >
                    <path
                        d="M363.324 195.529H149.896V377.132H363.324V195.529Z"
                        fill="white"
                    />
                    <path
                        d="M363.944 377.752H149.275V194.91H363.944V377.752ZM150.516 376.513H362.703V196.15H150.516V376.513Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M283.289 230.859H229.932V377.133H283.289V230.859Z"
                        fill="#2C73FF"
                    />
                    <path
                        d="M283.289 230.859H229.932V377.133H283.289V230.859Z"
                        fill="#2C73FF"
                    />
                    <path
                        opacity="0.2"
                        d="M150.516 208.855V238.321L363.323 251.932L362.697 215.574L150.516 208.855Z"
                        fill="black"
                    />
                    <path
                        d="M375.733 178.795H137.488V230.858H375.733V178.795Z"
                        fill="white"
                    />
                    <path
                        d="M376.353 231.479H136.867V178.176H376.353V231.479ZM138.108 230.239H375.112V179.415H138.108V230.239Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M283.289 173.836H229.932V230.238H283.289V173.836Z"
                        fill="#2C73FF"
                    />
                    <path
                        d="M250.896 124.655C264.98 145.511 251.709 179.061 251.709 179.061C251.709 179.061 215.6 178.788 201.516 157.932C187.433 137.075 200.704 103.525 200.704 103.525C200.704 103.525 236.813 103.798 250.896 124.655Z"
                        fill="#2C73FF"
                    />
                    <path
                        d="M257.36 124.655C243.276 145.511 256.547 179.061 256.547 179.061C256.547 179.061 292.656 178.788 306.74 157.932C320.824 137.075 307.553 103.525 307.553 103.525C307.553 103.525 271.444 103.798 257.36 124.655Z"
                        fill="#2C73FF"
                    />
                    <path
                        d="M219.385 140.367C222.469 140.367 224.969 137.87 224.969 134.789C224.969 131.708 222.469 129.211 219.385 129.211C216.301 129.211 213.801 131.708 213.801 134.789C213.801 137.87 216.301 140.367 219.385 140.367Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M252.887 196.77C255.97 196.77 258.47 194.272 258.47 191.192C258.47 188.111 255.97 185.613 252.887 185.613C249.803 185.613 247.303 188.111 247.303 191.192C247.303 194.272 249.803 196.77 252.887 196.77Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M252.887 318.871C255.97 318.871 258.47 316.374 258.47 313.293C258.47 310.212 255.97 307.715 252.887 307.715C249.803 307.715 247.303 310.212 247.303 313.293C247.303 316.374 249.803 318.871 252.887 318.871Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M234.275 158.961C236.331 158.961 237.998 157.296 237.998 155.242C237.998 153.188 236.331 151.523 234.275 151.523C232.219 151.523 230.553 153.188 230.553 155.242C230.553 157.296 232.219 158.961 234.275 158.961Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M290.113 127.352C292.169 127.352 293.836 125.687 293.836 123.633C293.836 121.579 292.169 119.914 290.113 119.914C288.057 119.914 286.391 121.579 286.391 123.633C286.391 125.687 288.057 127.352 290.113 127.352Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M265.916 214.744C267.972 214.744 269.639 213.079 269.639 211.025C269.639 208.972 267.972 207.307 265.916 207.307C263.86 207.307 262.193 208.972 262.193 211.025C262.193 213.079 263.86 214.744 265.916 214.744Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M243.58 263.088C245.636 263.088 247.303 261.423 247.303 259.369C247.303 257.315 245.636 255.65 243.58 255.65C241.524 255.65 239.857 257.315 239.857 259.369C239.857 261.423 241.524 263.088 243.58 263.088Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M262.814 279.822C264.87 279.822 266.537 278.157 266.537 276.104C266.537 274.05 264.87 272.385 262.814 272.385C260.758 272.385 259.092 274.05 259.092 276.104C259.092 278.157 260.758 279.822 262.814 279.822Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M258.471 349.861C260.527 349.861 262.193 348.197 262.193 346.143C262.193 344.089 260.527 342.424 258.471 342.424C256.415 342.424 254.748 344.089 254.748 346.143C254.748 348.197 256.415 349.861 258.471 349.861Z"
                        fill="#3F3D56"
                    />
                </g>

                <g
                    id="person-left"
                    className={
                        scene == 2
                            ? styles.personLeftVisible
                            : styles.personLeftInvisible
                    }
                >
                    <path
                        d="M143.102 193.527C150.65 193.527 156.77 187.414 156.77 179.873C156.77 172.332 150.65 166.219 143.102 166.219C135.553 166.219 129.434 172.332 129.434 179.873C129.434 187.414 135.553 193.527 143.102 193.527Z"
                        fill="#FFB8B8"
                    />
                    <path
                        d="M138.169 260.659C137.995 260.659 137.821 260.653 137.648 260.634C134.279 260.355 131.772 257.399 132.058 254.033C132.076 253.804 132.107 253.575 132.157 253.345C132.194 253.147 132.25 252.955 132.306 252.763L112.136 228.931C110.783 227.326 110.653 225.02 111.807 223.272L111.844 223.229L129.998 205.341C132.064 203.308 135.383 203.333 137.418 205.391C139.453 207.455 139.428 210.771 137.369 212.804C137.344 212.829 137.325 212.847 137.3 212.872L124.153 225.361L138.547 248.443C138.82 248.455 139.093 248.492 139.36 248.542C142.673 249.205 144.826 252.422 144.168 255.738C143.598 258.595 141.091 260.653 138.175 260.659H138.169Z"
                        fill="#FFB8B8"
                    />
                    <path
                        d="M165.611 369.887H172.43L175.675 343.602H165.611V369.887Z"
                        fill="#FFB8B8"
                    />
                    <path
                        d="M163.867 367.662H177.299C182.027 367.662 185.861 371.492 185.861 376.215V376.494H163.867V367.662Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M97.8047 360.459L103.668 363.948L119.917 343.024L111.268 337.873L97.8047 360.459Z"
                        fill="#FFB8B8"
                    />
                    <path
                        d="M97.4503 357.658L108.997 364.526C113.06 366.943 114.394 372.193 111.975 376.252L111.832 376.494L92.9336 365.251L97.4565 357.664L97.4503 357.658Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M174.043 360.601H166.405C165.121 360.601 164.048 359.634 163.918 358.364L156.218 286.045C156.168 285.586 155.759 285.258 155.3 285.301C155.008 285.332 154.748 285.518 154.63 285.785L140.738 316.515L116.144 356.585C115.487 357.645 114.147 358.066 112.999 357.564L103.171 353.244C101.906 352.686 101.329 351.211 101.887 349.947C101.93 349.854 101.974 349.761 102.03 349.674L124.638 311.854L131.575 279.983C129.391 266.162 138.741 245.615 138.84 245.411L138.883 245.311L164.637 235.252L164.768 235.407C174.924 268.703 181.116 304.54 176.543 358.296C176.425 359.585 175.346 360.583 174.049 360.589L174.043 360.601Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M137.84 248.684L137.723 248.43C137.667 248.312 131.94 236.245 127.523 223.346C125.81 218.301 126.288 212.773 128.844 208.099C131.431 203.376 135.886 199.955 141.122 198.672C150.41 196.447 159.928 201.356 163.495 210.2C166.994 218.946 165.884 228.286 164.829 235.631L164.81 235.78L164.674 235.842L137.847 248.684H137.84Z"
                        fill="#2C73FF"
                    />
                    <path
                        d="M159.673 172.157H137.971V162.705C142.736 160.815 147.395 159.21 150.212 162.705C155.436 162.705 159.673 166.939 159.673 172.157Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M136.767 161.037C123.794 161.037 120.164 177.283 120.164 186.449C120.164 191.563 122.478 193.391 126.114 194.005L127.398 187.162L130.407 194.296C131.431 194.296 132.504 194.284 133.615 194.259L134.632 192.164L136.903 194.222C146.011 194.234 153.369 195.561 153.369 186.443C153.369 177.326 150.187 161.031 136.767 161.031V161.037Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M194.437 179.222C194.518 179.371 194.598 179.526 194.667 179.687C196.038 182.774 194.636 186.387 191.546 187.757C191.335 187.85 191.118 187.931 190.894 187.999C190.702 188.061 190.51 188.104 190.311 188.148L179.044 217.26C178.281 219.212 176.321 220.433 174.23 220.266L174.174 220.253L149.76 212.909C146.987 212.072 145.411 209.147 146.248 206.376C147.086 203.605 150.014 202.031 152.788 202.868C152.819 202.874 152.85 202.886 152.881 202.899L170.153 208.44L183.53 184.751C183.412 184.503 183.313 184.249 183.226 183.989C182.215 180.766 184.014 177.332 187.24 176.328C190.02 175.46 193.029 176.669 194.431 179.222H194.437Z"
                        fill="#FFB8B8"
                    />
                </g>

                <g
                    id="person-right"
                    className={
                        scene == 2
                            ? styles.personRightVisible
                            : styles.personRightInvisible
                    }
                >
                    <path
                        d="M488.055 370.283H481.243L478.004 344.047L488.055 344.053V370.283Z"
                        fill="#FFB7B7"
                    />
                    <path
                        d="M489.787 376.878H467.836V376.599C467.836 371.882 471.664 368.064 476.379 368.064H489.787V376.878Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M504.682 370.283H511.488L514.727 344.047L504.676 344.053L504.682 370.283Z"
                        fill="#FFB7B7"
                    />
                    <path
                        d="M502.945 368.064H516.353C521.074 368.064 524.896 371.889 524.896 376.599V376.878H502.945V368.064Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M475.882 171.871C482.454 171.871 487.782 166.549 487.782 159.984C487.782 153.418 482.454 148.096 475.882 148.096C469.31 148.096 463.982 153.418 463.982 159.984C463.982 166.549 469.31 171.871 475.882 171.871Z"
                        fill="#FFB7B7"
                    />
                    <path
                        d="M467.121 241.767C467.121 241.767 463.361 257.411 465.241 264.917C467.121 272.422 472.755 323.73 472.755 323.73L476.514 364.395L491.547 365.647L489.668 318.096L491.547 276.804L502.194 316.85V364.544L518.133 365.17L518.474 303.09C518.474 303.09 519.727 254.287 511.587 248.033C503.447 241.779 502.194 240.527 502.194 240.527L467.121 241.779V241.767Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M489.811 172.944L504.068 180.45L505.948 245.517C505.948 245.517 497.808 240.509 485.282 246.769C472.755 253.029 464.615 248.647 464.615 248.647L472.129 220.489L468.995 182.947L473.475 178.888L489.811 172.938V172.944Z"
                        fill="#CBCBCB"
                    />
                    <path
                        d="M473.481 177.711L470.882 179.831C470.882 179.831 454.595 187.962 453.969 193.596L465.242 224.252C465.242 224.252 468.375 231.132 465.869 233.636C463.362 236.14 460.856 242.394 461.482 245.524C462.109 248.654 457.722 261.793 462.735 261.793C467.749 261.793 479.648 231.764 479.022 219.876C478.395 207.988 477.961 176.849 473.481 177.717V177.711Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M484.426 173.644L490.165 172.689C490.165 172.689 511.459 178.323 514.592 187.707L505.825 209.605C505.825 209.605 512.085 249.651 510.212 249.651H508.332C508.332 249.651 513.345 253.407 510.212 254.659C507.078 255.911 499.565 265.92 497.685 261.538C495.805 257.156 484.532 205.229 485.785 193.967C487.038 182.706 483.322 174.828 484.433 173.65L484.426 173.644Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M486.919 148.343L487.478 147.221L484.673 145.827C484.673 145.827 481.577 140.8 475.981 142.213C470.385 143.627 467.866 144.469 467.866 144.469L465.068 145.876L466.47 147.271L463.951 148.114L465.632 148.951L463.672 150.073L464.727 155.837C464.727 155.837 466.476 151.467 469.845 153.134C473.214 154.802 479.369 152.273 479.369 152.273L484.717 162.611C484.717 162.611 485.821 158.985 487.788 159.952C487.788 159.952 492.813 151.957 486.919 148.337V148.343Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M504.335 258.135C503.168 255.284 504.533 252.024 507.387 250.859C507.691 250.735 508.008 250.636 508.33 250.567L510.744 230.889L518.599 237.564L515.105 255.228C515.558 258.29 513.437 261.141 510.365 261.594C507.797 261.972 505.309 260.54 504.341 258.135H504.335Z"
                        fill="#FFB7B7"
                    />
                    <path
                        d="M470.74 256.779C471.602 253.823 469.896 250.724 466.936 249.869C466.62 249.776 466.297 249.714 465.969 249.676L461.483 230.363L454.379 237.832L459.727 255.025C459.603 258.118 462.016 260.727 465.112 260.851C467.706 260.957 470.032 259.271 470.74 256.773V256.779Z"
                        fill="#FFB7B7"
                    />
                    <path
                        d="M504.64 182.613C504.64 182.613 512.78 179.483 515.286 188.866C517.793 198.25 520.926 217.024 520.926 217.024C521.081 227.462 520.529 227.462 520.926 237.67L519.046 247.054L508.399 245.802L508.865 221.908L503.393 197.624L504.646 182.606L504.64 182.613Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M459.603 189.834L455.744 190.249C455.744 190.249 452.09 192.964 452.09 199.224C452.09 205.484 451.463 224.252 451.463 224.252C451.463 224.252 451.109 224.692 453.97 240.522C455.366 248.238 457.773 251.876 457.773 251.876L465.547 244.662L464.616 226.13L467.123 216.12L459.609 189.84L459.603 189.834Z"
                        fill="#2F2E41"
                    />
                </g>

                <g
                    id="leaves-2"
                    className={
                        scene == 2
                            ? styles.leaves2Visible
                            : styles.leaves2Invisible
                    }
                >
                    <path
                        d="M25.4752 348.2C30.4262 349.936 35.9232 348.969 39.9808 345.647C45.0621 341.389 46.6567 334.366 47.9534 327.871L51.7876 308.645L43.7592 314.167C37.9892 318.14 32.0828 322.237 28.0872 327.989C24.0916 333.74 22.3482 341.593 25.5558 347.816"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M26.7156 372.813C25.7043 365.462 24.6682 358.012 25.3754 350.58C26.0021 343.979 28.0185 337.533 32.1257 332.246C34.3034 329.445 36.9527 327.046 39.9556 325.156C40.7373 324.66 41.457 325.9 40.6815 326.396C35.4822 329.674 31.4619 334.521 29.2035 340.236C26.7032 346.589 26.2999 353.512 26.7342 360.255C26.9948 364.334 27.5469 368.387 28.1053 372.435C28.1984 372.813 27.975 373.197 27.6028 373.315C27.2181 373.42 26.8272 373.197 26.7218 372.813H26.7156Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M33.9863 361.012C36.1082 364.235 39.7625 366.119 43.6216 365.983C48.4982 365.753 52.5682 362.351 56.2287 359.121L67.0553 349.57L59.8893 349.229C54.7335 348.981 49.4474 348.752 44.5398 350.339C39.6322 351.926 35.1031 355.75 34.2097 360.826"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M23.8615 377.04C28.7256 368.443 34.3653 358.886 44.4473 355.83C47.2517 354.981 50.1863 354.683 53.1085 354.937C54.0268 355.018 53.7972 356.431 52.879 356.351C47.99 355.948 43.1072 357.237 39.062 360.001C35.1657 362.648 32.1318 366.336 29.5632 370.24C27.9873 372.633 26.5789 375.124 25.1706 377.616C24.7177 378.409 23.4023 377.839 23.8615 377.033V377.04Z"
                        fill="#F2F2F2"
                    />
                </g>

                <g
                    id="squiggle"
                    className={
                        scene == 2
                            ? styles.squiggleVisible
                            : styles.squiggleInvisible
                    }
                >
                    <path
                        d="M410.588 70.5406C402.492 70.0324 395.133 65.6752 390.796 58.8263C390.195 57.8718 389.413 56.8181 389.829 55.6405C390.126 54.7108 391.026 54.1096 392 54.1902C393.222 54.3389 394.01 55.5352 394.165 56.6818C394.339 57.9834 393.421 58.9999 392.285 59.4833C389.518 60.6609 386.726 58.8387 384.468 57.3884C379.331 54.0724 373.679 51.0788 367.456 50.7007C361.698 50.3536 355.519 52.6097 352.317 57.5991C351.759 58.473 353.149 59.2788 353.707 58.411C356.884 53.465 363.305 51.6862 368.889 52.4485C372.215 52.9754 375.428 54.06 378.388 55.6715C379.92 56.4649 381.409 57.3512 382.867 58.2747C384.257 59.2416 385.727 60.0845 387.266 60.7911C389.829 61.8448 393.222 61.758 395.015 59.3408C396.684 57.0785 395.394 53.3225 392.602 52.6841C390.554 52.2812 388.569 53.6138 388.166 55.6529C388.067 56.155 388.073 56.6694 388.178 57.1714C388.457 58.504 389.462 59.8056 390.238 60.8903C394.959 67.5284 402.448 71.6687 410.588 72.1521C411.624 72.2017 411.618 70.5964 410.588 70.5468V70.5406Z"
                        fill="#CCCCCC"
                    />
                </g>

                <g
                    id="balloon-1"
                    className={
                        scene == 2
                            ? styles.balloon1Visible
                            : styles.balloon1Invisible
                    }
                >
                    <path
                        d="M505.812 26.6084C505.142 11.3922 493 -0.439902 482.645 0.0125547C472.29 0.465012 461.228 13.3074 461.892 28.5297C462.512 42.6179 473.426 53.7682 482.775 55.014C482.676 55.107 482.602 55.2185 482.546 55.3425L481.615 57.549C481.386 58.0944 481.64 58.7204 482.186 58.9497C482.341 59.0117 482.509 59.0427 482.67 59.0303L487.77 58.6956C488.359 58.6584 488.806 58.1502 488.769 57.5552C488.756 57.3878 488.707 57.2267 488.626 57.0841L487.416 55.014C487.373 54.9458 487.323 54.8776 487.261 54.8156C496.499 52.7951 506.426 40.7089 505.806 26.596L505.812 26.6084Z"
                        fill="#2C73FF"
                    />
                    <path
                        d="M491.908 11.5717C498.746 18.0052 499.335 28.6535 493.261 35.806C492.597 36.5994 493.931 37.4981 494.589 36.7109C501.035 28.951 500.402 17.5342 493.131 10.5366C492.392 9.82383 491.158 10.8589 491.902 11.5779L491.908 11.5717Z"
                        fill="white"
                    />
                </g>

                <g
                    id="balloon-2"
                    className={
                        scene == 2
                            ? styles.balloon2Visible
                            : styles.balloon2Invisible
                    }
                >
                    <path
                        d="M411.481 105.286C402.355 93.0825 385.647 90.1384 377.345 96.3364C369.044 102.534 367.139 119.375 376.266 131.572C384.716 142.865 400.022 145.946 408.454 141.718C408.429 141.849 408.423 141.985 408.454 142.121L408.932 144.47C409.049 145.053 409.614 145.425 410.197 145.307C410.359 145.276 410.514 145.202 410.644 145.103L414.67 141.96C415.136 141.595 415.223 140.925 414.857 140.454C414.751 140.324 414.621 140.219 414.472 140.144L412.307 139.115C412.232 139.078 412.152 139.053 412.071 139.041C418.567 132.18 419.963 116.604 411.494 105.286H411.481Z"
                        fill="#CCCCCC"
                    />
                    <path
                        d="M391.516 100.68C400.785 102.149 407.281 110.622 406.288 119.944C406.189 120.973 407.802 120.966 407.895 119.944C408.851 109.903 401.89 100.829 391.938 99.1307C390.927 98.9572 390.492 100.507 391.51 100.68H391.516Z"
                        fill="white"
                    />
                </g>

                <g
                    id="balloon-3"
                    className={
                        scene == 2
                            ? styles.balloon3Visible
                            : styles.balloon3Invisible
                    }
                >
                    <path
                        d="M106.838 120.589C114.817 107.61 111.386 91.012 102.557 85.5949C93.7285 80.1778 77.3616 84.628 69.3766 97.6067C61.9873 109.619 64.7792 124.971 71.8273 131.231C71.697 131.256 71.5667 131.305 71.4551 131.374L69.4449 132.681C68.9485 133.004 68.812 133.667 69.1347 134.163C69.2277 134.299 69.3456 134.417 69.4883 134.504L73.8996 137.082C74.4083 137.38 75.066 137.206 75.3638 136.698C75.4506 136.555 75.5003 136.394 75.5065 136.227L75.6616 133.834C75.6616 133.754 75.6616 133.667 75.643 133.586C84.4283 137.082 99.4303 132.626 106.832 120.583L106.838 120.589Z"
                        fill="#CCCCCC"
                    />
                    <path
                        d="M103.748 100.353C105.802 109.502 100.324 118.662 91.284 121.185C90.2913 121.476 90.8931 122.964 91.8796 122.679C101.571 119.859 107.446 110.047 105.349 100.18C105.138 99.1758 103.537 99.3431 103.748 100.353Z"
                        fill="white"
                    />
                </g>

                <g id="line">
                    <path
                        d="M0 340.875C0 341.402 0.422813 341.825 0.949334 341.825H546.051C546.577 341.825 547 341.402 547 340.875C547 340.349 546.577 339.926 546.051 339.926H0.949334C0.422813 339.926 0 340.349 0 340.875Z"
                        fill="#3F3D56"
                    />
                </g>
            </g>
        </svg>
    )
}
